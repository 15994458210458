exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portraits-based-on-orig-index-js": () => import("./../../../src/pages/portraits based on orig index.js" /* webpackChunkName: "component---src-pages-portraits-based-on-orig-index-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/Gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

